<template>
    <main class="main mt-8" dir="rtl">
        <div class="container">
            <div class="row">         
                <div class="col-lg-5 order-lg-last dashboard-content edit">
                    <div>
                        <b-tabs content-class="mt-3">
                            <b-tab title="الحساب" active>
                                <form @submit.prevent="edit_account">
                                    <div class="form-group required-field">
                                        <p class="h4 mb-2">* الاسم</p>
                                        <input type="text" class="form-control mb-4" v-model="user.name" id="name" name="name" required>
                                    </div>

                                    <div class="form-group required-field">
                                        <p class="h4 mb-2">* الهاتف</p>
                                        <input type="text" class="form-control" v-model="user.phone" id="phone" name="phone" required>
                                    </div>

                                    <div class="required mt-3 text-right"></div>
                                    <div class="form-footer">
                                        <button type="submit" class="btn btn-primary">حفظ</button>
                                    </div>
                                </form> 
                            </b-tab>
                            <b-tab title="الامان">
                                <p class="h4 mb-2">كلمة المرور الحالية</p>
                                <input type="password" class="form-control" v-model="currentPassword" id="currentPassword" name="currentPassword" required>
                                <p class="h4 mb-2 mt-4">كلمة المرور الجديدة</p>
                                <input type="password" class="form-control" v-model="newPassword" id="newPassword" name="newPassword" required>
                                <div class="required mt-3 text-right" id="error"></div>
                                <button type="submit" class="btn btn-primary" @click="changePassword">ارسال الطلب</button>
                            </b-tab>
                      </b-tabs>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4 order-lg-last">
                    <img src="../../assets/images/account/image.png" alt="">
                </div>
                <aside class="sidebar col-lg-2 text-right" dir="ltr" style="margin-left: auto;">
                    <div class="widget widget-dashboard">
                        <h3 class="widget-title">الحساب</h3>

                        <ul class="list">
                            <li class="active li-active"><a @click.prevent>معلومات الحساب</a></li>
                            <li><a href="/orders">الطلبيات</a></li>
                            <li><a href="/wallet">المحفظة</a></li>
                            <li><a href="/addresses">العناوين</a></li>
                        </ul>
                    </div>
                </aside>
                
            </div>
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {
            user: {},
            code: null,
            currentPassword: '',
            newPassword: '',
        }
    },
    mounted() {
        console.log(localStorage.getItem('token'))
        this.axios
        .get(process.env.VUE_APP_URL+`/api/customers/${localStorage.getItem('customer_id')}`, {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        .then(response => {
            this.user = response.data
        })
    },
    methods: {
        changePassword: function() {            
            this.axios({
                url: 
process.env.VUE_APP_URL+'/api/account/change-password',
                method: 'POST',
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
                data: { 
                    currentPassword : this.currentPassword,
                    newPassword : this.newPassword },
            })
            .then(()  => {
                this.$swal({
                    icon: 'success',
                    title: 'لقد تمت عملية تغيير كلمة المرور بنجاح',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(() => {
                if(this.currentPassword == "" || this.newPassword == "") {
                    document.getElementById("error").innerText = "يجب تعبئة كلمة المرور"
                } else if(this.newPassword.length < 7) {
                    document.getElementById("error").innerText = "يجب ان تكون كلمة المرور اكبر من 7 حروف"
                } else
                document.getElementById("error").innerText = "كلمة المرور الحالية غير صحيحة ... "
                this.currentPassword = ""
                this.newPassword = ""
                document.getElementById("error").innerText = ""
            })
        },
        edit_account: function() {
            this.axios({
                url: 
process.env.VUE_APP_URL+'/api/customers/update-profile',
                method: 'PUT',
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
                data: this.user,
            })
            .then(() => {
                localStorage.removeItem('username')
                localStorage.setItem('username', this.user.name)
                this.$swal({
                    icon: 'success',
                    title: 'لقد تم تعديل الحساب بنجاح',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
        }
    }
}
</script>

<style scoped>

    .edit {
        background-color: rgb(255, 255, 255);
        border-radius: 1rem;
        padding: 31px !important;
        margin-left: 4rem;
        margin-bottom: 4rem;
    }

    form {
        margin-bottom: 0px;
    }

    .form-footer {
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    .tr {
        text-align: right;
        /* transform: rotate(180deg) */
    }
    .account-info-items {
        border-radius: 0.2rem;
    }
    .account-info-types {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show>.btn-primary.dropdown-toggle {
        border-color: #bb3e6e;
        background-color: #bb3e6e;
        color: #f6f6f6;
    }
    .btn-primary:hover, .btn-primary:focus, .btn-primary.focus {
        border-color: #60718d;
        background-color: #60718d;
        color: #f6f6f6;
        box-shadow: none;
    }
    .list li a:before {
        display: inline-block;
        position: absolute;
        width: 0;
        height: 0;
        margin: 0.6rem 0 0 -2rem;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 0px;
        border-right: 5px solid #333;
        content: '';
    }
    .li-active {
        background-color: #eee;
        text-decoration: none;
    }
    .list .li-active a {
        color: #b9336e !important;
    }
    main {
        text-align: right;
    }
</style>